@import "styles/Colors.scss";
@import "styles/Typography.scss";

.radio-button {
  &__container {
    display: flex;
    height: 40px;
    padding: 0px 8px;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: var(--border-radius-sm) !important;
    border: 1px solid var(--color-brand-primary-grey-200) !important;

    &:not(&-disabled):hover {
      border-color: var(--color-border-primary-hover) !important;
      background-color: var(--color-surface-secondary) !important;
      cursor: pointer;
    }

    &:not(&-disabled):focus-within {
      border: 2px solid var(--color-border-primary-hover) !important;
      background: var(--color-surface-secondary) !important;
    }

    &-checked {
      border-color: var(--color-border-primary-hover) !important;
      background-color: var(--color-surface-secondary) !important;
    }
    &-disabled {
      background-color: var(--color-surface-primary-disabled) !important;
    }
  }

  &__wrapper {
    display: flex !important;
    align-items: center !important;
  }
}
