@import "styles/Typography.scss";
@import "styles/Colors.scss";

.onboarding-info-tile {
  display: flex;
  padding: 16px 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: var(--border-radius-md) var(--border-radius-md) 0px 0px;
  background: var(--color-surface-primary);
  border-bottom: 1px solid var(--color-border-primary);

  &:last-child {
    border-bottom: none;
    border-radius: 0px 0px var(--border-radius-md) var(--border-radius-md);
  }

  &__content {
    display: flex;
    align-items: center;
    gap: 12px;
  }


  &__icon-wrapper {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    background-color: var(--color-surface-primary-hover) !important;
    color: var(--color-icon-primary) !important;
  }

  &__text {
    display: flex;
    flex-direction: column;

    .tile-heading {
      @extend .heading-h4-medium;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: var(--color-neutral-10);
    }

    .tile-description {
      @extend .heading-h5-regular;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: var(--color-neutral-varian-60);
    }
  }

  &__state {
    &.pending {
      background: var(--color-primitives-warning-100);
      color: var(--color-text-warning);
    }

    &.in-progress {
      background: var(--color-primitives-warning-100);
      color: var(--color-text-warning);
    }

    &.scheduled {
      background: var(--color-primitives-success-100);
      color: var(--color-text-success);
    }

    &.paid {
      background: var(--color-primitives-success-100);
      color: var(--color-text-success);
    }

    &.completed {
      background: var(--color-primitives-success-100);
      color: var(--color-text-success);
    }

    &.failed {
      background: var(--color-primitives-danger-100);
      color: var(--color-text-danger);
    }
  }
}
