.eye-icon {
  color: var(--color-icon-secondary);
}

.mui-theme-border-colors .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.mui-theme-border-colors .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.mui-theme-border-colors .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline,
.mui-theme-border-colors .MuiOutlinedInput-root.Mui-focused.MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-primitives-primary-600) !important;
}
