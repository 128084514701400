.icon-style {
  color: #fff;
  width: 20px;
  height: 21px;
}

.list-item-button {
  padding: 0px 23px !important;
}

.list-item-icon {
  min-width: 40px !important;
}

.edge-logo-style {
  margin-bottom: 15px;
  padding: 0px 25px;
}

.customer-profile-avatar-sidebar {
  color: var(--color-brand-primary-grey-300) !important;
  text-transform: uppercase;
}

.customer-profile-avatar-sidebar-nested div {
  color: var(--color-brand-primary-grey-300) !important;
  text-transform: uppercase;
}
/* TODO to be removed with sidebar revamp flag used in sidebar.component.js */
.edge-sidebar-logo-rebrand {
  position: relative !important;
  margin-bottom: 0 !important;
  padding-top: 16px !important;
  padding-bottom: 44px !important;
}
