@import "styles/Typography.scss";
@import "styles/Colors.scss";
@import "styles/BorderRadius.scss";

.peers-revamped-container {
  background-color: #ffff;
  height: 112vh;
}

.employee-paystubs-details-slips-revamped {
  @extend .heading-h2-semibold;
  color: var(--color-text-headings-primary);
  margin-left: 5px;
}

.employee-paystubs-details-revamped {
  background-color: #ffff;
  padding-top: 18px;
  flex-direction: column;
  align-items: center;
  gap: var(8px);
  padding-bottom: 80px;
}

.employee-paystubs-details-dA-revamped {
  display: flex;
  padding: 18px 45px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background-color: #ffff;
}

.employee-paystubs-search {
  display: flex;
  width: 348px;
  height: 35px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  margin-top: 25px;
  margin-bottom: 15px;
}

.peers-all-result {
  color: var(--color-text-body-secondary);
  font-family: var(--font-family-heading);
  font-size: var(--font-size-lg);
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.employee-peers-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin-bottom: 13px;
}

.peers-pagination-icons-wrapper {
  display: flex;
  flex-direction: row;
  gap: 9px;
}

.peers-pagination-icon-disabled {
  padding: 2px;
  color: #b7afaf;
  cursor: not-allowed;
}

.peers-pagination-icon-enabled {
  color: #000;
  cursor: pointer;
  padding: 2px;
}

.peers-pagination-icon-enabled:hover {
  cursor: pointer;
  background-color: var(--color-primitives-neutral-100);
  border-radius: 50%;
}

.employee-peers-list-revamped {
  display: flex;
  justify-content: space-between;
  padding: var(--font-size-sm, 12px) 20px;
  align-items: center;
  gap: 44px;
  align-self: stretch;
  border-radius: var(--font-size-sm) !important;
  border: 1px solid var(--color-border-primary) !important;
  background: var(--color-surface-primary) !important;
  margin-bottom: 12px !important;
}

.employee-peers-cella {
  display: flex;
  width: 400px;
  align-items: center;
  gap: 24px;
}

.avatar-image {
  width: 95px !important;
  height: 94px !important;
  border-radius: 100px !important;
}

.avatar-contain {
  object-fit: contain;
}

.avatar-cover {
  object-fit: cover;
}

.country-icon-box {
  position: absolute;
  left: 75px;
  bottom: 2px;
  border: 2px solid white;
  border-radius: 99px;
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.peer-info {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  gap: var(--1, 8px) !important;
}

.peer-name {
  @extend .heading-h3-semibold;
  font-family: var(--font-family-heading) !important;
  color: var(--color-text-headings-primary);
}

.peer-join-date {
  @extend .para-body-s-regular;
  font-family: var(--font-family-heading) !important;
  color: var(--color-text-body-primary);
}

.peer-designation {
  @extend .para-body-s-regular;
  margin-top: -8px !important;
  color: var(--color-primitives-primary-600) !important;
}

.employee-peers-cellb {
  display: flex;
  width: 400px;
  align-items: flex-start;
  gap: var(--font-size-sm, 12px);
}

.employee-email {
  @extend .para-body-m-regular;
  font-family: var(--font-family-paragraph) !important;
  margin-top: 1px;
  color: var(--color-text-body-secondary);
}

.employee-contact-btn {
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--color-primitives-primary-500);
}

.employee-peers-cellc {
  gap: 5px;
  width: 20%;
}

.peers-view-profile-btn {
  margin-right: 20px !important;
}

.employee-peers-search-field {
  background: #ffffff;
  border: 1px solid #bfc2ff;
  border-radius: 24px;
  width: 200px;
  height: 35px;
}

.employee-paystubs-details-dAA-revamped {
  width: 18px;
  height: 18px;
  align-self: center;
}

.peer-input-prop {
  color: var(--color-text-headings-primary) !important;
  font-family: var(--font-family-inter) !important;
  font-size: var(--font-size-lg) !important;
  font-style: var(--font-style-normal) !important;
  font-weight: var(--font-weight-regular) !important;
  line-height: var(--line-height-xl) !important;
}

.peer-search-icon {
  display: flex;
  align-items: flex-start;
  color: #afafaf !important;
}

.mail-icon {
  color: var(--color-primitives-primary-600) !important;
}

.peer-input-prop::placeholder {
  color: #000 !important;
}

