:root {
  /* Surface Colors */
  --color-surface-primary: var(--color-primitives-white-white);
  --color-surface-danger: var(--danger-50);
  --color-surface-primary-hover: var(--color-primitives-neutral-50);
  --color-surface-primary-pressed: var(--color-primitives-neutral-100);
  --color-surface-primary-disabled: var(--color-primitives-neutral-100);
  --color-surface-page-primary: var(--color-primitives-white-white);
  --color-surface-page-secondary: var(--color-primitives-primary-50);
  --color-surface-secondary: var(--color-brand-primary-blue-50);
  --color-surface-secondary-hover: var(--color-brand-primary-blue-100);
  --color-surface-secondary-inverse: var(--color-primitives-primary-600);
  --color-surface-success: var(--color-primitives-success-50);
  --color-surface-action-success: var(--color-primitives-success-600);
  --color-surface-action-danger: var(--color-primitives-danger-600);

  --color-surface-action-secondary: var(--color-primitives-primary-100);
  --color-surface-action-primary: var(--color-primitives-primary-600);
  --color-surface-action-neutral: var(--color-primitives-neutral-200);
  --color-surface-action-warning: var(--color-primitives-warning-600);
  --color-surface-action-primary-disabled: var(--color-primitives-neutral-200);

  /* Hover Colors */
  --color-surface-action-primary-hover: var(--color-primitives-primary-700);
  --color-surface-action-secondary-hover: var(--color-primitives-primary-200);
  --color-surface-action-danger-hover: var(--color-primitives-danger-700);
  --color-surface-action-warning-hover: var(--color-primitives-warning-700);
  --color-surface-action-success-hover: var(--color-primitives-success-700);
  --color-surface-action-neutral-hover: var(--color-brand-primary-grey-300);

  /* Text Colors */
  --color-text-headings-primary: var(--color-primitives-black-black);
  --color-text-headings-secondary: var(--color-primitives-neutral-700);
  --color-text-body-primary: var(--color-primitives-neutral-700);
  --color-text-body-secondary: var(--color-primitives-black-black);
  --color-text-body-tertiary: var(--color-primitives-primary-200);
  --color-text-highlight: var(--color-primitives-highlight);
  --color-text-disabled: var(--color-primitives-neutral-500);
  --color-text-success: var(--color-primitives-success-800);
  --color-text-on-action-white: var(--color-primitives-white-white);
  --color-text-on-disabled: var(--color-primitives-neutral-700);
  --color-text-action: var(--color-primitives-primary-600);
  --color-text-danger: var(--color-primitives-danger-800);
  --color-text-warning: var(--color-primitives-warning-800);

  /* Border Colors */
  --color-border-primary: var(--color-primitives-neutral-200);
  --color-border-primary-hover: var(--color-primitives-primary-600);
  --color-border-focused: var(--color-primitives-primary-700);
  --color-border-action: var(--color-primitives-primary-600);
  --color-border-danger: var(--color-primitives-danger-600);
  --color-border-warning: var(--color-primitives-warning-600);
  --color-border-success: var(--color-primitives-success-600);
  --color-border-neutral: var(--color-primitives-neutral-600);
  --color-border-tertiary: var(--color-primitive-white);

  /* Elevation */
  --color-elevation-default: #00000028;

  /* Icon Colors */
  --color-icon-primary: var(--color-primitives-primary-600);
  --color-icon-secondary: var(--color-primitives-neutral-400);
  --color-icon-success: var(--color-primitives-success-600);
  --color-icon-danger: var(--color-primitives-danger-600);
  --color-icon-black: var(--color-primitives-black-black);
  --color-icon-information-inverse: var(--color-brand-primary-blue-200);

  /* Chip Colors */
  /* Warning */
  --color-chip-warning-text: var(--color-primitives-warning-800);
  --color-chip-warning-bg: var(--color-primitives-warning-100);
  --color-chip-warning-hover: #f7df88;
  --color-chip-warning-focus-border: #efb437;
  --color-chip-warning-thumbnail: var(--color-primitives-warning-600);
  --color-chip-warning-delete: #e79328;
  --color-chip-warning-outline-border: var(--color-primitives-warning-600);

  /* Default */
  --color-chip-default-text: var(--color-primitives-black-black);
  --color-chip-default-bg: var(--color-brand-primary-grey-100);
  --color-chip-default-hover: #dfdfdf;
  --color-chip-default-focus-border: var(--color-brand-primary-grey-400);
  --color-chip-default-thumbnail: var(--color-brand-primary-grey-300);
  --color-chip-default-delete: var(--color-brand-primary-grey-500);
  --color-chip-default-outline-border: var(--color-brand-primary-grey-200);

  /* Success */
  --color-chip-success-text: var(--color-primitives-success-800);
  --color-chip-success-bg: var(--color-primitives-success-100);
  --color-chip-success-hover: #9df3c7;
  --color-chip-success-focus-border: #36d08a;
  --color-chip-success-thumbnail: var(--color-primitives-success-600);
  --color-chip-success-delete: #21b573;
  --color-chip-success-outline-border: var(--color-primitives-success-600);

  /* Error */
  --color-chip-error-text: var(--color-primitives-danger-800);
  --color-chip-error-bg: var(--color-primitives-danger-100);
  --color-chip-error-hover: #fdc4c0;
  --color-chip-error-focus-border: var(--color-primitives-danger-600);
  --color-chip-error-thumbnail: var(--color-primitives-danger-600);
  --color-chip-error-delete: #fb6c61;
  --color-chip-error-outline-border: var(--color-primitives-danger-600);

  /* Info */
  --color-chip-info-text: var(--color-primitives-white-white);
  --color-chip-info-bg: var(--color-brand-primary-blue-600);
  --color-chip-info-hover: var(--color-brand-primary-blue-700);
  --color-chip-info-focus-border: var(--color-brand-primary-blue-900);
  --color-chip-info-thumbnail: var(--color-brand-primary-blue-800);
  --color-chip-info-delete: var(--color-brand-primary-blue-300);
  --color-chip-info-outline-border: var(--color-brand-primary-blue-600);

  /* Primary */
  --color-chip-primary-text: var(--color-brand-primary-blue-600);
  --color-chip-primary-bg: var(--color-brand-primary-blue-100);
  --color-chip-primary-hover: var(--color-brand-primary-blue-200);
  --color-chip-primary-focus-border: var(--color-brand-primary-blue-400);
  --color-chip-primary-thumbnail: var(--color-brand-primary-blue-600);
  --color-chip-primary-delete: var(--color-brand-primary-blue-400);
  --color-chip-primary-outline-border: var(--color-brand-primary-blue-600);

  /* Secondary */
  --color-chip-secondary-text: var(--color-brand-primary-blue-600);
  --color-chip-secondary-bg: var(--color-brand-primary-blue-100);
  --color-chip-secondary-hover: var(--color-brand-primary-blue-200);
  --color-chip-secondary-focus-border: var(--color-brand-primary-blue-400);
  --color-chip-secondary-thumbnail: var(--color-brand-primary-blue-300);
  --color-chip-secondary-delete: var(--color-brand-primary-blue-400);
  --color-chip-secondary-outline-border: var(--color-brand-primary-blue-200);

  /* Primitive Colors */
  --temp-color-primitives-primary-800: var(--color-brand-primary-purple-600);
  --color-primitives-primary-900: var(--color-brand-primary-purple-900);
  --color-primitives-primary-800: var(--color-brand-primary-purple-800);
  --color-primitives-primary-700: var(--color-brand-primary-purple-700);
  --color-primitives-primary-600: var(--color-brand-primary-purple-600);
  --color-primitives-primary-500: var(--color-brand-primary-purple-500);
  --color-primitives-primary-400: var(--color-brand-primary-purple-400);
  --color-primitives-primary-300: var(--color-brand-primary-purple-300);
  --color-primitives-primary-200: var(--color-brand-primary-purple-200);
  --color-primitives-primary-100: var(--color-brand-primary-purple-100);
  --color-primitives-primary-50: var(--color-brand-primary-purple-50);

  --color-primitives-secondary-800: var(--color-brand-primary-grey-800);
  --color-primitives-secondary-600: var(--color-brand-primary-grey-600);

  --color-primitives-neutral-900: var(--color-brand-primary-grey-900);
  --color-primitives-neutral-700: var(--color-brand-primary-grey-700);
  --color-primitives-neutral-600: var(--color-brand-primary-grey-600);
  --color-primitives-neutral-500: var(--color-brand-primary-grey-500);
  --color-primitives-neutral-400: var(--color-brand-primary-grey-400);
  --color-primitives-neutral-300: var(--color-brand-primary-grey-300);
  --color-primitives-neutral-200: var(--color-brand-primary-grey-200);
  --color-primitives-neutral-100: var(--color-brand-primary-grey-100);
  --color-primitives-neutral-50: var(--color-brand-primary-grey-50);

  --color-primitives-highlight: var(--color-brand-primary-purple-600);

  --color-primitives-success-800: #10563b;
  --color-primitives-success-700: #136f4b;
  --color-primitives-success-600: #168254;
  --color-primitives-success-100: #cbf9df;
  --color-primitives-success-50: #e9fdf2;

  --color-primitives-warning-800: #803b1a;
  --color-primitives-warning-700: #a24c1b;
  --color-primitives-warning-600: #c86d21;
  --color-primitives-warning-100: #fbefc4;
  --color-primitives-warning-50: #fefaea;

  --color-primitives-danger-800: #952019;
  --color-primitives-danger-700: #b9241c;
  --color-primitives-danger-600: #e3382d;
  --color-primitives-danger-100: #fededc;
  --color-primitives-danger-50: #fef0ef;
  --color-primitives-danger-10: #d32f2f;

  --color-primitives-black-black: #000000;
  --color-primitives-white-white: #ffffff;
  --color-primitive-white: #ffffff33;

  --color-neutral-varian-60: #918f9a;
  --color-neutral-10: #14151f;

  /* Brand Colors */
  --color-brand-primary-blue-900: #151968;
  --color-brand-primary-blue-800: #262b86;
  --color-brand-primary-blue-700: #353ba8;
  --color-brand-primary-blue-600: #3d43bb;
  --color-brand-primary-blue-500: #4e54bd;
  --color-brand-primary-blue-400: #8086d7;
  --color-brand-primary-blue-300: #a1a5dd;
  --color-brand-primary-blue-200: #cbccef;
  --color-brand-primary-blue-150: #ddddff;
  --color-brand-primary-blue-100: #eaeafc;
  --color-brand-primary-blue-50: #f5f5fd;
  --color-brand-primary-blue-25: #f6f6ff;

  --color-brand-primary-grey-900: #363636;
  --color-brand-primary-grey-800: #676767;
  --color-brand-primary-grey-700: #7b7b7b;
  --color-brand-primary-grey-600: #888888;
  --color-brand-primary-grey-500: #999999;
  --color-brand-primary-grey-400: #afafaf;
  --color-brand-primary-grey-300: #c8c8c8;
  --color-brand-primary-grey-200: #dfdfdf;
  --color-brand-primary-grey-100: #ededed;
  --color-brand-primary-grey-50: #f7f7f7;

  --color-icon-sidebar: var(--color-primitives-primary-300);
  --color-surface-sidebar-menu-hover: var(--color-primitives-primary-800);

  /* Edge Logo Colors */
  --color-edge-logo-purple: #914de8;
  --color-edge-logo-white: #ffffff;
  --color-edge-logo-black: #000000;
  --color-edge-logo-dark: #4a0f70;
  --color-edge-logo-light: #e5d9f9;

  --color-brand-primary-purple-900: #30114d;
  --color-brand-primary-purple-800: #44146c;
  --color-brand-primary-purple-700: #622498;
  --color-brand-primary-purple-600: #8139d0;
  --color-brand-primary-purple-500: #914de8;
  --color-brand-primary-purple-400: #9b6ee8;
  --color-brand-primary-purple-300: #c6aafc;
  --color-brand-primary-purple-200: #e5d9f9;
  --color-brand-primary-purple-100: #f4eefd;
  --color-brand-primary-purple-75: #fbf9ff;
  --color-brand-primary-purple-50: #f5f2fc;

  --primary-blue-900: #151968;
  --primary-blue-800: #262b86;
  --primary-blue-700: #353ba8;
  --primary-blue-600: #3d43bb;
  --primary-blue-500: #4e54bd;
  --primary-blue-400: #8086d7;
  --primary-blue-300: #a1a5dd;
  --primary-blue-200: #cbccef;
  --primary-blue-100: #eaeafc;
  --primary-blue-50: #f5f5fd;

  --primary-50: #f5f5fd;
  --primary-100: #eaeafc;
  --primary-200: #cbccef;
  --primary-400: #8086d7;
  --primary-500: #4e54bd;
  --primary-600: #3d43bb;
  --primary-800: #262b86;
  --primary-900: #151968;
  --danger-50: #fef0ef;

  /* Gradients */
  --background-default-gradient: linear-gradient(
    0deg,
    var(--color-brand-primary-purple-75) 0%,
    var(--color-brand-primary-purple-100) 100%
  );
}
