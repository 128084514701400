.create-password-main-container {
  background-color: var(--color-surface-primary);
  height: 100vh;

  .email-password-main-container {
    padding: 20px 0px;
    background-color: var(--color-surface-primary);

    .logo-container {
      margin-bottom: 78px;
      @media (max-width: 768px) {
        margin-bottom: 28px;
      }
    }

    .edge-logo {
      width: 91px;
      object-fit: cover;
    }

    .change-password-inner-container {
      width: 440px;
      max-width: 440px;

      .subheader-text {
        color: var(--color-text-body-primary);
      }

      .label-color {
        color: var(--color-text-headings-secondary);
      }

      .icon-color {
        color: var(--color-icon-secondary);
      }

      .email-container {
        display: flex;
        padding: 12px 16px;
        align-items: center;
        gap: 12px;
        align-self: stretch;
        border-radius: var(--border-radius-sm, 4px);
        background: var(--color-surface-primary-hover);

        .email-icon-bg {
          display: flex;
          width: 40px;
          height: 40px;
          justify-content: center;
          align-items: center;
          border-radius: 40px;
          background: var(--color-surface-primary);
        }

        .email-color {
          color: var(--color-text-body-secondary);
        }
      }
    }

    @media (max-width: 768px) {
      padding-top: 24px;
    }
  }

  .slider-main-container {
    display: flex;
    align-items: center;
    justify-content: center;

    &.default {
      background: var(--background-default-gradient);
    }

    @media (max-width: 768px) {
      padding: 32px 8px;
      min-height: 392px;
    }
  }

  .slider-main-container-ios {
    display: flex;
    align-items: center;
    justify-content: center;

    &.default {
      background: var(--background-default-gradient);
    }

    @media (max-width: 768px) {
      padding: 32px 8px 120px 8px;
      min-height: 392px;
    }
  }
}

@media (max-width: 768px) {
  .create-password-main-container {
    overflow-y: scroll;

    .email-password-main-container {
      .change-password-inner-container {
        padding: 20px;
        padding-bottom: 28px;
        padding-top: 24px;
        width: 100%;
      }
    }
  }
}
